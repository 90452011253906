import React from "react";
import { Song } from "../../types/songs/Song";
import { Link } from "react-router-dom";

interface SongItemProps {
  song: Song;
}

const SongItem: React.FC<SongItemProps> = ({ song }) => {
  return (
    <div className="p-1">
      <div className="rounded border border-gray-400 hover:bg-gray-300">
        <Link to={`/songs/${song.slug}`} className="flex items-center">
          <img
            className="w-10 h-10 rounded mr-2"
            src={`https://img.youtube.com/vi/${song.youtubeId}/default.jpg`}
            alt={song.slug}
          />
          <span className="bg-gray-300 rounded uppercase font-mono px-1 mr-1">
            {song.language}
          </span>
          <span className="truncate ...">{song.title}</span>
        </Link>
      </div>
    </div>
  );
};

export default SongItem;
