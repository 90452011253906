import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Artist } from "../../types/artists/Artist";
import useAxios, { configure } from "axios-hooks";
import LRU from "lru-cache";
import DatePicker from "react-datepicker";
import CONSTANTS from "../../constants";
import "react-datepicker/dist/react-datepicker.css";
import { BaseHeader, BaseButton } from "../../components/Base";

const NewArtistPage: React.FC = () => {
  const [artist, setArtist] = useState<Artist>({
    gender: "f",
    nationality: "de",
    name: "",
    birthName: "",
    bornIn: "de",
    bornOn: new Date().toISOString().slice(0, 10),
    debutedIn: "de",
    debutedOn: new Date().toISOString().slice(0, 10),
    slug: "",
    songsCount: 0,
    songsSentimentSum: 0,
  });

  const updateValue = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLFormElement;
    setArtist({ ...artist, [target.name]: target.value });
  };

  const [{ data, loading, error }, executePost] = useAxios(
    {
      url: "/creators/artists",
      method: "POST",
    },
    { manual: true }
  );

  const addArtist = () => {
    const cache = new LRU({ max: 20 });
    configure({ cache });

    executePost({
      data: { artist },
    });
  };

  if (data) return <Redirect to={`/artists/${data.slug}`} />;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>API Error</p>;

  return (
    <div>
      <BaseHeader title="Add new artist" />
      <form className="flex flex-wrap">
        <div className="mb-4 w-full lg:w-1/2">
          <label className="block text-sm font-bold mb-2">Name *</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="name"
            value={artist.name}
            onChange={updateValue}
            required
          />
        </div>
        <div className="mb-4 w-full lg:w-1/2">
          <label className="block text-sm font-bold mb-2">Birth name *</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="birthName"
            value={artist.birthName}
            onChange={updateValue}
            required
          />
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Gender *</label>
          <select
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="gender"
            value={artist.gender}
            onChange={updateValue}
          >
            {Object.entries(CONSTANTS.GENDERS).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Nationality</label>
          <select
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="nationality"
            value={artist.nationality}
            onChange={updateValue}
          >
            {Object.entries(CONSTANTS.COUNTRIES).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/2">
          <label className="block text-sm font-bold mb-2">Website URL</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="websiteUrl"
            value={artist.websiteUrl}
            onChange={updateValue}
          />
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Born in *</label>
          <select
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="bornIn"
            value={artist.bornIn}
            onChange={updateValue}
          >
            {Object.entries(CONSTANTS.COUNTRIES).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Born on *</label>
          <DatePicker
            selected={new Date(artist.bornOn)}
            onChange={(date: Date) =>
              setArtist({
                ...artist,
                bornOn: date.toISOString().slice(0, 10) || "",
              })
            }
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            wrapperClassName="w-full"
            dateFormat="yyyy/MM/dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Now"
          />
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Debuted in *</label>
          <select
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="debutedIn"
            value={artist.debutedIn}
            onChange={updateValue}
          >
            {Object.entries(CONSTANTS.COUNTRIES).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Debuted on *</label>
          <DatePicker
            selected={new Date(artist.debutedOn)}
            onChange={(date: Date) =>
              setArtist({
                ...artist,
                debutedOn: date.toISOString().slice(0, 10) || "",
              })
            }
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            wrapperClassName="w-full"
            dateFormat="yyyy/MM/dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Now"
          />
        </div>
        <div className="mb-4 w-full">
          <label className="block text-sm font-bold mb-2">Description</label>
          <textarea
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="description"
            rows={6}
            value={artist.description}
            onChange={updateValue}
          />
        </div>
        <div className="flex w-full justify-end">
          <BaseButton title="Submit" onClick={addArtist} />
        </div>
      </form>
    </div>
  );
};

export default NewArtistPage;
