import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";

const Layout: React.FC = props => {
  return (
    <div className="bg-gray-100 text-gray-800 antialiased">
      <NavBar />
      <div className="container mx-auto pt-12 px-4">{props.children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
