import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import LRU from "lru-cache";
import CONSTANTS from "../../constants";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BaseHeader, BaseButton } from "../../components/Base";
import Axios from "axios";
import { Artist } from "../../types/artists/Artist";
import { Song } from "../../types/songs/Song";

const NewSongPage: React.FC = () => {
  const [artists, setArtists] = useState<Artist[]>();
  const [artistSlug, setArtistSlug] = useState();
  const [artistSongs, setArtistSongs] = useState<Song[]>();
  const [nationality, setNationality] = useState("kr");

  const [song, setSong] = useState<Song>({
    title: "",
    artistId: 0,
    language: "de",
    lyrics: "",
    releasedOn: new Date().toISOString().slice(0, 10),
    slug: "",
  });

  useEffect(() => {
    async function fetchArtists() {
      try {
        const { data } = await Axios.get(
          `${CONSTANTS.API_ENDPOINT}creators/artists`
        );
        setArtists(data);
      } catch (error) {
        // setError
      }
    }
    fetchArtists();
  }, []);

  useEffect(() => {
    async function fetchSongs() {
      console.log(artistSlug);
      try {
        const { data } = await Axios.get<Song[]>(
          `${CONSTANTS.API_ENDPOINT}creators/artists/${artistSlug}/songs`
        );
        setArtistSongs(
          data.sort((songA, songB) => songA.slug.localeCompare(songB.slug))
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchSongs();
  }, [artistSlug]);

  const [{ data, loading, error }, executePost] = useAxios(
    {
      url: "/contents/songs",
      method: "POST",
    },
    { manual: true }
  );

  const addSong = () => {
    const cache = new LRU({ max: 20 });
    configure({ cache });

    executePost({
      data: { song },
    });
  };

  if (data) return <Redirect to={`/songs/${data.slug}`} />;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>API Error</p>;

  const updateValue = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLFormElement;
    let newValue;

    switch (target.name) {
      case "youtubeId":
        const vIdIndex = target.value.indexOf("v=", 10) + 2;
        newValue = vIdIndex > 10 ? target.value.substr(vIdIndex, 11) : "";
        break;
      case "artistId":
        setArtistSlug(target.options[target.selectedIndex].dataset.slug);
        newValue = target.value;
        break;
      default:
        newValue = target.value;
        break;
    }

    setSong({ ...song, [target.name]: newValue });
  };

  return (
    <div>
      <BaseHeader title="Add new song" />

      <form className="flex flex-wrap">
        <div className="mb-4 w-1/3 lg:w-1/5">
          <label className="block text-sm font-bold mb-2">
            Artist's nationality *
          </label>
          <select
            className="shadow border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="artistNationality"
            value={nationality}
            onChange={(e) => setNationality(e.target.value)}
          >
            {Object.entries(CONSTANTS.COUNTRIES).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-2/3 lg:w-2/5">
          <label className="block text-sm font-bold mb-2">Artist *</label>
          <select
            className="shadow border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="artistId"
            placeholder="Select nationality first"
            onChange={updateValue}
          >
            <option value="">Select</option>
            {!!artists &&
              artists
                .filter((artist) => artist.nationality === nationality)
                .map((artist) => (
                  <option
                    value={artist.id}
                    key={artist.id}
                    data-slug={artist.slug}
                  >
                    {artist.slug}
                  </option>
                ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-2/5">
          <label className="block text-sm font-bold mb-2">Already added</label>
          <select
            className="shadow border rounded w-full p-2 focus:outline-none focus:shadow-outline h-20"
            multiple={true}
          >
            {!!artistSongs &&
              artistSongs.map((song) => (
                <option key={song.slug}>{song.title}</option>
              ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/5">
          <label className="block text-sm font-bold mb-2">Language *</label>
          <select
            className="shadow border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="language"
            value={song.language}
            onChange={updateValue}
          >
            {Object.entries(CONSTANTS.LANGUAGES).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/5">
          <label className="block text-sm font-bold mb-2">Released on *</label>
          <DatePicker
            selected={new Date(song.releasedOn)}
            onChange={(date: Date) =>
              setSong({
                ...song,
                releasedOn: date.toISOString().slice(0, 10) || "",
              })
            }
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            wrapperClassName="w-full"
            dateFormat="yyyy/MM/dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Now"
          />
        </div>
        <div className="mb-4 w-full lg:w-2/5">
          <label className="block text-sm font-bold mb-2">Title *</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="title"
            value={song.title}
            onChange={updateValue}
          />
        </div>
        <div className="mb-4 w-full lg:w-1/5">
          <label className="block text-sm font-bold mb-2">Youtube URL *</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="youtubeId"
            value={song.youtubeId}
            onChange={updateValue}
          />
        </div>

        <div className="mb-4 w-full">
          <label className="block text-sm font-bold mb-2">Lyrics *</label>
          <textarea
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="lyrics"
            rows={14}
            value={song.lyrics}
            onChange={updateValue}
          />
        </div>
        <div className="flex w-full justify-end">
          <BaseButton title="Submit" onClick={addSong} />
        </div>
      </form>
    </div>
  );
};

export default NewSongPage;
