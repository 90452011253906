import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import useAxios from "axios-hooks";
import SongItem from "../../components/songs/SongItem";
import { Song } from "../../types/songs/Song";
import { BaseButton, BaseHeader } from "../../components/Base";
import YouTube from "react-youtube";
import AuthService from "../../services/Auth";

interface ArtistMatchProps {
  slug: string;
}

const ShowArtistPage: React.FC<RouteComponentProps<ArtistMatchProps>> = (
  props
) => {
  const { match } = props;

  const [{ data: artist, loading, error }] = useAxios({
    url: `/creators/artists/${match.params.slug}`,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>API Error</p>;

  const opts = {
    height: "360",
    width: "640",
    playerVars: {
      playlist:
        artist.songs.length > 1
          ? artist.songs
              .slice(1)
              .map((song: Song) => song.youtubeId)
              .join(",")
          : [],
    },
  };

  return (
    <div>
      <BaseHeader title={artist.name} />
      <div className="bg-gray-200 rounded-md px-2 py-1 text-xs font-semibold text-gray-700 uppercase inline-flex items-center mr-2 mb-2">
        {artist.nationality}
      </div>
      <div className="bg-gray-200 rounded-md px-2 py-1 text-xs font-semibold text-gray-700 uppercase inline-flex items-center mr-2 mb-2">
        {artist.songsCount} {artist.songsCount > 1 ? "songs" : "song"}
      </div>
      <div className="bg-gray-200 rounded-md px-2 py-1 text-xs font-semibold text-gray-700 uppercase inline-flex items-center mr-2 mb-2">
        {(artist.songsSentimentSum / artist.songsCount).toFixed(6)}
      </div>
      {artist.websiteUrl && (
        <a href={artist.websiteUrl} target="_blank" rel="noopener noreferrer">
          <span className="bg-gray-200 rounded-md px-2 py-1 border-gray-500 border text-xs font-semibold text-gray-700 inline-flex items-center mr-2 mb-2 hover:bg-gray-300">
            Website
          </span>
        </a>
      )}
      <div className="text-xl font-bold lg:text-2xl mb-4">Songs</div>
      <div className="flex flex-wrap">
        {artist.songs.map((song: Song) => (
          <div className="w-full md:w-1/2 lg:w-1/3" key={song.id}>
            <SongItem song={song} />
          </div>
        ))}
      </div>
      {artist.songsCount > 0 && (
        <div className="flex justify-center">
          <YouTube
            videoId={artist.songs[0].youtubeId}
            className="max-w-full"
            opts={opts}
          />
        </div>
      )}
      {!!AuthService.getToken() && (
        <div className="flex w-full justify-end">
          <Link to={`/artists/${artist.slug}/edit`}>
            <BaseButton title="Edit" onClick={() => true} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default ShowArtistPage;
