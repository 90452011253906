import React, { useState } from "react";
import useAxios from "axios-hooks";
import { Song } from "../../types/songs/Song";
import SongItem from "../../components/songs/SongItem";
import { BaseHeader, BaseButton } from "../../components/Base";
import FilterBox from "../../components/FilterBox";
import AuthService from "../../services/Auth";
import { Link } from "react-router-dom";

const SongsPage: React.FC = () => {
  const [languages, setLanguages] = useState([] as string[]);

  const [{ data, loading, error }] = useAxios({
    url: "/contents/songs",
  });

  if (loading) return <></>;
  if (error) return <p>Error!</p>;

  let filteredSongs = data.filter((song: Song) =>
    languages.includes(song.language)
  );

  return (
    <div>
      <BaseHeader title="Songs" />
      <FilterBox criteria="l" onChange={setLanguages} />
      {!!AuthService.getToken() && (
        <div className="flex w-full justify-end">
          <Link to="/songs/new">
            <BaseButton title="New" onClick={() => true} />
          </Link>
        </div>
      )}
      <div className="flex flex-wrap -mx-1">
        {filteredSongs.map((song: Song) => (
          <div key={song.id} className="w-full md:w-1/2 lg:w-1/3">
            <SongItem song={song} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SongsPage;
