import React, { useState, useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import useAxios, { configure } from "axios-hooks";
import LRU from "lru-cache";
import CONSTANTS from "../../constants";
import { Artist } from "../../types/artists/Artist";
import Axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BaseHeader, BaseButton } from "../../components/Base";

interface ArtistMatchProps {
  slug: string;
}

const EditPage: React.FC<RouteComponentProps<ArtistMatchProps>> = (props) => {
  const { match } = props;

  const [artist, setArtist] = useState<Artist>({
    gender: "f",
    name: "",
    nationality: "de",
    birthName: "",
    bornIn: "de",
    bornOn: new Date().toISOString().slice(0, 10),
    debutedIn: "de",
    debutedOn: new Date().toISOString().slice(0, 10),
    slug: "",
    songsCount: 0,
    songsSentimentSum: 0,
  });

  useEffect(() => {
    async function fetchArtist() {
      try {
        const { data } = await Axios.get(
          `${CONSTANTS.API_ENDPOINT}creators/artists/${match.params.slug}`
        );
        setArtist(data);
      } catch (error) {
        // setError
      }
    }
    fetchArtist();
  }, [match.params.slug]);

  const [{ data, loading, error }, executePatch] = useAxios(
    {
      url: `/creators/artists/${match.params.slug}`,
      method: "PATCH",
    },
    { manual: true }
  );

  const updateValue = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLFormElement;
    setArtist({ ...artist, [target.name]: target.value });
  };

  const updateArtist = () => {
    const cache = new LRU({ max: 20 });
    configure({ cache });

    executePatch({
      data: { artist },
    });
  };

  if (data) return <Redirect to={`/artists/${data.slug}`} />;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>API Error</p>;

  return (
    <div>
      <BaseHeader title="Edit artist" />
      <form className="flex flex-wrap">
        <div className="mb-4 w-full lg:w-1/2">
          <label className="block text-sm font-bold mb-2">Name *</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="name"
            value={artist.name}
            onChange={updateValue}
          />
        </div>
        <div className="mb-4 w-full lg:w-1/2">
          <label className="block text-sm font-bold mb-2">Birth name *</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="birthName"
            value={artist.birthName}
            onChange={updateValue}
          />
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Gender *</label>
          <select
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="gender"
            value={artist.gender}
            onChange={updateValue}
          >
            {Object.entries(CONSTANTS.GENDERS).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Nationality *</label>
          <select
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="nationality"
            value={artist.nationality}
            onChange={updateValue}
          >
            {Object.entries(CONSTANTS.COUNTRIES).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/2">
          <label className="block text-sm font-bold mb-2">Website URL</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="websiteUrl"
            value={artist.websiteUrl}
            onChange={updateValue}
          />
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Born in *</label>
          <select
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="bornIn"
            value={artist.bornIn}
            onChange={updateValue}
          >
            {Object.entries(CONSTANTS.COUNTRIES).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Born on *</label>
          <DatePicker
            selected={new Date(artist.bornOn)}
            onChange={(date: Date) =>
              setArtist({
                ...artist,
                bornOn: date.toISOString().slice(0, 10) || "",
              })
            }
            className="shadow appearance-none border rounded p-2 w-full focus:outline-none focus:shadow-outline"
            wrapperClassName="w-full"
            dateFormat="yyyy/MM/dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Now"
          />
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Debuted in *</label>
          <select
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="debutedIn"
            value={artist.debutedIn}
            onChange={updateValue}
          >
            {Object.entries(CONSTANTS.COUNTRIES).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Debuted on *</label>
          <DatePicker
            selected={new Date(artist.debutedOn)}
            onChange={(date: Date) =>
              setArtist({
                ...artist,
                debutedOn: date.toISOString().slice(0, 10) || "",
              })
            }
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            wrapperClassName="w-full"
            dateFormat="yyyy/MM/dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Now"
          />
        </div>
        <div className="mb-4 w-full">
          <label className="block text-sm font-bold mb-2">Description</label>
          <textarea
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="description"
            rows={6}
            value={artist.description}
            onChange={updateValue}
          />
        </div>
        <div className="flex w-full justify-end">
          <BaseButton title="Submit" onClick={updateArtist} />
        </div>
      </form>
    </div>
  );
};

export default EditPage;
