import React from "react";
import Layout from "../components/Layout";
import { BaseHeader } from "../components/Base";

const HowItWorks: React.FC = () => {
  return (
    <Layout>
      <BaseHeader title="How It Works" />
      <div className="flex flex-col items-center max-w-full">
        <div className="w-full lg:w-1/2 border-2 rounded-lg bg-red-100 px-4 py-2 mb-4">
          <div className="font-semibold text-lg mb-2">Analyze contents</div>
          <div className="text-gray-700 text-base">
            Every content is analyzed by AI when added.
          </div>
        </div>
        <div className="w-full lg:w-1/2 border-2 rounded-lg bg-red-200 px-4 py-2 mb-4">
          <div className="font-semibold text-lg mb-2">Suggest contents</div>
          <div className="text-gray-700 text-base">
            Contents list is suggested based on input sentiment.
          </div>
        </div>
        <div className="w-full lg:w-1/2 border-2 rounded-lg bg-red-300 px-4 py-2 mb-4">
          <div className="font-semibold text-lg mb-2">Customize list</div>
          <div className="text-gray-700 text-base">
            User then customize their content list.
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HowItWorks;
