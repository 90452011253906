import React, { useState } from "react";
import Layout from "../components/Layout";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useDebounce } from "use-debounce";
import SongsBySentiment from "../components/songs/SongsBySentiment";

const Home: React.FC = () => {
  const [sentimentValue, setSentimentValue] = useState<number>(
    parseFloat(localStorage.getItem("sentimentValue") || "0")
  );
  const [sentimentalWord, setSentimentalWord] = useState(
    getSentimentalWord(sentimentValue)
  );
  const [debouncedSentiment] = useDebounce(sentimentValue, 1000);

  function getSentimentalWord(value: number) {
    let sentimental: string;
    if (value < -0.6) {
      sentimental = "Gloomier";
    } else if (value < -0.2) {
      sentimental = "Gloomy";
    } else if (value < 0.2) {
      sentimental = "Moderate";
    } else if (value < 0.6) {
      sentimental = "Happy";
    } else {
      sentimental = "Happier";
    }
    return sentimental;
  }

  const handleChange = (value: number) => {
    setSentimentalWord(getSentimentalWord(value));
    setSentimentValue(value);
    localStorage.setItem("sentimentValue", value.toString());
  };

  return (
    <Layout>
      <div
        className="bg-gray-100 rounded-lg shadow-lg border border-gray-300 px-6 py-3 mb-3"
        style={{
          backgroundColor: `rgba(120,120,120, ${(
            (sentimentValue - 1.2) /
            -4
          ).toFixed(1)})`,
        }}
      >
        <div className="font-bold mb-2 text-center text-xl text-gray-800 lg:text-2xl">
          {sentimentalWord} songs for You
        </div>
        <Slider
          className="mb-8"
          min={-1}
          max={1}
          defaultValue={sentimentValue}
          step={0.000001}
          marks={{ "-1": "Flat", 0: "Neutral", 1: "Sharp" }}
          onChange={handleChange}
        />
      </div>
      <SongsBySentiment sentiment={debouncedSentiment} />
    </Layout>
  );
};

export default Home;
