import React from "react";
import {
  withRouter,
  RouteComponentProps,
  Route,
  Switch
} from "react-router-dom";
import Login from "../../pages/auth/Login";

const AuthRouter: React.FC<RouteComponentProps> = props => {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.url}/login`} exact component={Login} />
    </Switch>
  );
};

export default withRouter(AuthRouter);
