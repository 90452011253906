import React from "react";
import AuthRouter from "../../routes/auth";
import Layout from "../../components/Layout";

const AuthPage: React.FC = () => {
  return (
    <Layout>
      <AuthRouter />
    </Layout>
  );
};

export default AuthPage;
