import React, { useState } from "react";
import useAxios from "axios-hooks";
import { Artist } from "../../types/artists/Artist";
import ArtistItem from "../../components/artists/ArtistItem";
import { BaseHeader, BaseButton } from "../../components/Base";
import FilterBox from "../../components/FilterBox";
import { Link } from "react-router-dom";
import AuthService from "../../services/Auth";

const ArtistsPage: React.FC = () => {
  const [countries, setCountries] = useState([] as string[]);

  const [{ data: artists, loading, error }] = useAxios({
    url: "/creators/artists",
  });

  if (loading) return <></>;
  if (error) return <p>Error!</p>;

  let filteredArtists = artists.filter((artist: Artist) =>
    countries.includes(artist.nationality)
  );

  return (
    <div>
      <BaseHeader title="Artists" />
      <FilterBox criteria="c" onChange={setCountries} />
      {!!AuthService.getToken() && (
        <div className="flex w-full justify-end">
          <Link to="/artists/new">
            <BaseButton title="New" onClick={() => true} />
          </Link>
        </div>
      )}
      <div className="flex flex-wrap -mx-1">
        {filteredArtists.map((artist: Artist) => (
          <div className="w-full md:w-1/2 lg:w-1/4" key={artist.id}>
            <ArtistItem artist={artist} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default ArtistsPage;
