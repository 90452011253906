import React from "react";
import SongRouter from "../../routes/songs";
import Layout from "../../components/Layout";

const SongPage: React.FC = () => {
  return (
    <Layout>
      <SongRouter />
    </Layout>
  );
};

export default SongPage;
