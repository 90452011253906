import React from "react";
import Layout from "../components/Layout";
import { BaseHeader } from "../components/Base";

const About: React.FC = () => {
  return (
    <Layout>
      <BaseHeader title="About YouSpur" />
      <div className="flex flex-col max-w-full">
        <div className="px-2 mb-6">
          <div
            className="font-semibold text-lg border-b-2 border-gray-500 mb-2"
            style={{ width: "fit-content" }}
          >
            AI-powered contents analysis
          </div>
          <div className="text-gray-700 text-base">
            YouSpur utilizes AI to analyze contents. For example, Natural
            Language Processing/Understanding is used for text-based contents.
          </div>
        </div>
        <div className="px-2 mb-6">
          <div
            className="font-semibold text-lg border-b-2 border-gray-500 mb-2"
            style={{ width: "fit-content" }}
          >
            Vs. YouTube
          </div>
          <div className="text-gray-700 text-base">
            YouTube (probably) recommendes similar contents.
            <br />
            YouSpur helps you to navigate various contents by sentiment.
          </div>
        </div>
        <div className="px-2 mb-6">
          <div
            className="font-semibold text-lg border-b-2 border-gray-500 mb-2"
            style={{ width: "fit-content" }}
          >
            A family service of Buenaworks
          </div>
          <div className="text-gray-700 text-base">
            <a
              href="//www.buenaworks.com"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold"
            >
              Buenaworks
            </a>
            &nbsp; provides some fun services.
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
