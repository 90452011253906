import React, { useState } from "react";
import useAxios from "axios-hooks";
import AuthService from "../../services/Auth";
import { Redirect } from "react-router-dom";

const LoginPage: React.FC = () => {
  const [identity, setIdentity] = useState({ email: "", password: "" });

  const updateValue = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLFormElement;
    setIdentity({ ...identity, [target.name]: target.value });
  };

  const [{ data, loading, error }, executePost] = useAxios(
    {
      url: "/auth/identity/callback",
      method: "POST"
    },
    { manual: true }
  );

  const handleSubmit = () => {
    AuthService.removeToken();
    executePost({
      data: { user: identity }
    });
  };

  if (data) {
    if (data.token) {
      AuthService.setToken(data.token);
      return <Redirect to="/" />;
    } else {
      return <Redirect to="/auth/login" />;
    }
  }
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  return (
    <div>
      <div className="text-2xl mb-4">Login YouSpur</div>
      <form>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Email</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="email"
            value={identity.email}
            onChange={updateValue}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Password</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="password"
            name="password"
            value={identity.password}
            onChange={updateValue}
          />
        </div>
      </form>
      <div className="flex justify-end">
        <button onClick={handleSubmit}>Login</button>
      </div>
    </div>
  );
};

export default LoginPage;
