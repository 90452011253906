const AuthService = {
  getToken(): string | null {
    return localStorage.getItem("jwt");
  },
  setToken(jwt: string) {
    return localStorage.setItem("jwt", jwt);
  },
  removeToken() {
    localStorage.removeItem("jwt");
  },
};

export default AuthService;
