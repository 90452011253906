import React from "react";
import { Link } from "react-router-dom";

const NavBar: React.FC = () => (
  <div className="fixed top-0 left-0 max-w-full w-full border-b-2 bg-indigo-800 text-gray-200 shadow-md z-10">
    <div className="flex justify-between px-4 py-2">
      <div className="flex">
        <button className="mr-4">
          <Link
            to="/"
            className="font-bold text-lg tracking-tight hover:text-gray-400"
          >
            YouSpur
          </Link>
        </button>
      </div>
      <div>
        <Link
          to="/artists"
          className="text-lg tracking-tight mr-4 hover:text-gray-400"
        >
          Artists
        </Link>
        <Link
          to="/songs"
          className="text-lg tracking-tight hover:text-gray-400"
        >
          Songs
        </Link>
      </div>
    </div>
  </div>
);

export default NavBar;
