import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import useAxios from "axios-hooks";
import YouTube from "react-youtube";
import { BaseButton, BaseHeader } from "../../components/Base";
import AuthService from "../../services/Auth";

interface SongMatchProps {
  slug: string;
}

const ShowSongPage: React.FC<RouteComponentProps<SongMatchProps>> = (props) => {
  const { match } = props;

  const [{ data: song, loading, error }] = useAxios({
    url: `/contents/songs/${match.params.slug}`,
  });

  const youtubeOpts = {
    height: "360",
    width: "640",
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>API Error</p>;

  return (
    <div>
      <BaseHeader title={song.title} />
      <div className="bg-gray-200 rounded-md px-2 py-1 text-xs font-semibold text-gray-700 uppercase inline-flex items-center mr-2 mb-2">
        {song.language}
      </div>
      <Link
        to={`/artists/${song.artist.slug}`}
        className="bg-gray-200 rounded-md px-2 py-1 border-gray-500 border text-xs font-semibold text-gray-700 inline-flex items-center mr-2 mb-2 hover:bg-gray-300"
      >
        {song.artist.name}
      </Link>
      <div className="bg-gray-200 rounded-md px-2 py-1 text-xs font-semibold text-gray-700 inline-flex items-center mr-2 mb-2">
        {song.releasedOn}
      </div>
      <div className="bg-gray-200 rounded-md px-2 py-1 text-xs font-semibold text-gray-700 inline-flex items-center mr-2 mb-2">
        {song.nlpSentiment}
      </div>
      <div>
        {song.nlpConcepts &&
          song.nlpConcepts.slice(0, 3).map((concept: string) => (
            <div
              className="bg-gray-200 rounded-md px-2 py-1 text-xs font-semibold text-gray-700 inline-flex items-center mr-2 mb-2"
              key={concept}
            >
              {concept}
            </div>
          ))}
      </div>
      <div className="flex justify-center">
        <YouTube
          videoId={song.youtubeId}
          className="max-w-full"
          opts={youtubeOpts}
        />
      </div>
      {!!AuthService.getToken() && (
        <div className="flex w-full justify-end">
          <Link to={`/songs/${song.slug}/edit`}>
            <BaseButton title="Edit" onClick={() => true} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default ShowSongPage;
