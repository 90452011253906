import React from "react";
import { Artist } from "../../types/artists/Artist";
import { Link } from "react-router-dom";

interface ArtistItemProps {
  artist: Artist;
}

const ArtistItem: React.FC<ArtistItemProps> = (props) => {
  return (
    <div className="p-1">
      <Link to={`/artists/${props.artist.slug}`}>
        <div className="rounded border border-gray-400 hover:bg-gray-300 p-1">
          <div>
            <h4 className="font-semibold truncate ...">{props.artist.name}</h4>
          </div>
          <div className="text-sm text-gray-700 font-normal font-mono">
            <span className="bg-gray-300 rounded-sm uppercase px-1 mr-2">
              {props.artist.nationality}
            </span>
            <span className="bg-gray-300 rounded-sm uppercase px-1 mr-2">
              {props.artist.songsCount}
            </span>
            <span className="bg-gray-300 rounded-sm uppercase font-sans px-1 mr-2">
              {(
                props.artist.songsSentimentSum / props.artist.songsCount
              ).toFixed(6)}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ArtistItem;
