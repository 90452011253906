interface ConstantsType {
  API_ENDPOINT: string;
  COUNTRIES: object;
  GENDERS: object;
  LANGUAGES: object;
}

const test = {
  API_ENDPOINT: "http://localhost:4000/api/"
};

const development = {
  API_ENDPOINT: "http://localhost:4000/api/"
};

const production = {
  API_ENDPOINT: "https://api.youspur.com/api/"
};

const general = {
  COUNTRIES: {
    au: "Australia",
    ca: "Canada",
    de: "Germany",
    fr: "France",
    gb: "United Kingdom",
    ie: "Ireland",
    jp: "Japan",
    kr: "Korea, Republic of",
    no: "Norway",
    se: "Sweden",
    us: "United States"
  },
  GENDERS: { f: "Female", m: "Male", x: "Group" },
  LANGUAGES: {
    de: "Deutsch (German)",
    en: "English",
    fr: "français (French)",
    ja: "日本語 (Japanese)",
    ko: "한글 (Korean)",
    no: "Norsk (Norwegian)",
    sv: "Svenska (Swedish)"
  }
};

let constants: ConstantsType;

switch (process.env.NODE_ENV) {
  case "test":
    constants = { ...test, ...general };
    break;
  case "development":
    constants = { ...development, ...general };
    break;
  case "production":
    constants = { ...production, ...general };
    break;
  default:
    constants = { ...development, ...general };
    break;
}

export default constants;
