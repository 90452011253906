import React, { useState } from "react";
import useAxios from "axios-hooks";
import YouTube from "react-youtube";
import { Song } from "../../types/songs/Song";
import SongItem from "./SongItem";
import FilterBox from "../FilterBox";

interface SongsBySentimentPropsType {
  sentiment: number;
}

const SongsBySentiment: React.FC<SongsBySentimentPropsType> = (props) => {
  const [filters, setFilters] = useState([] as string[]);

  const [{ data: songs, loading, error }] = useAxios({
    url: "/contents/songs",
    params: { sentiment: props.sentiment },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>API Error</p>;

  let filteredSongs = songs
    .filter((song: Song) => filters.includes(song.language))
    .slice(0, 9);

  let opts = {
    height: "360",
    width: "640",
    playerVars: {
      playlist:
        filteredSongs.length > 1
          ? filteredSongs
              .slice(1)
              .map((song: Song) => song.youtubeId)
              .join(",")
          : [],
    },
  };

  return (
    <div>
      <FilterBox criteria="l" onChange={setFilters} />
      <div className="flex flex-wrap mb-4">
        {filteredSongs.map((song: Song) => (
          <div className="w-full md:w-1/2 lg:w-1/3" key={song.id}>
            <SongItem song={song} />
          </div>
        ))}
      </div>
      {filteredSongs.length > 0 && (
        <div className="flex justify-center">
          <YouTube
            videoId={filteredSongs[0].youtubeId}
            className="max-w-full"
            opts={opts}
          />
        </div>
      )}
    </div>
  );
};

export default SongsBySentiment;
