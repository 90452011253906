import React from "react";

interface PropsType {
  title: string;
}

interface ButtonPropsType {
  title: string;
  onClick(event: React.MouseEvent<HTMLElement>): void;
}

export const BaseHeader: React.FC<PropsType> = props => {
  return (
    <div className="px-2 py-1 mb-2 border shadow-md">
      <div className="font-bold text-xl lg:text-2xl">{props.title}</div>
    </div>
  );
};

export const BaseButton: React.FC<ButtonPropsType> = props => {
  return (
    <button
      onClick={props.onClick}
      className="bg-blue-500 text-white font-bold px-4 py-2 rounded hover:bg-blue-700"
    >
      {props.title}
    </button>
  );
};

export default { BaseHeader, BaseButton };
