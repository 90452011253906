import React from "react";
import {
  RouteComponentProps,
  withRouter,
  Switch,
  Route,
} from "react-router-dom";
import AuthService from "../../services/Auth";
import New from "../../pages/songs/New";
import Edit from "../../pages/songs/Edit";
import Songs from "../../pages/songs/Songs";
import Show from "../../pages/songs/Show";

const SongRouter: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;

  const protectedRoutes = !!AuthService.getToken() && [
    <Route path={`${match.url}/new`} exact component={New} key="new" />,
    <Route
      path={`${match.url}/:slug/edit`}
      exact
      component={Edit}
      key="edit"
    />,
  ];

  return (
    <Switch>
      {protectedRoutes}
      <Route path={`${match.url}/:slug`} exact component={Show} />
      <Route path={`${match.url}/`} component={Songs} />
    </Switch>
  );
};

export default withRouter(SongRouter);
