import React from "react";
import ArtistRouter from "../../routes/artists";
import Layout from "../../components/Layout";

const ArtistPage: React.FC = () => {
  return (
    <Layout>
      <ArtistRouter />
    </Layout>
  );
};

export default ArtistPage;
