import React from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import Axios from "axios";
import { configure } from "axios-hooks";
import CONSTANTS from "../constants";
import Home from "../pages/Home";
import About from "../pages/About";
import HowItWorks from "../pages/HowItWorks";
import Artist from "../pages/artists";
import Song from "../pages/songs";
import Auth from "../pages/auth";
import AuthService from "../services/Auth";

const axios = Axios.create({
  baseURL: CONSTANTS.API_ENDPOINT
});

if (!!AuthService.getToken())
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + AuthService.getToken();

configure({ axios });

const Root: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" exact component={About} />
      <Route path="/how-it-works" exact component={HowItWorks} />
      <Route path="/artists" component={Artist} />
      <Route path="/songs" component={Song} />
      <Route path="/auth" component={Auth} />
      <Redirect path="*" to="/" />
    </Switch>
  </BrowserRouter>
);

export default Root;
