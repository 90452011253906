import React, { useState, useEffect } from "react";
import CONSTANTS from "../constants";

interface PropsType {
  criteria: string;
  onChange: React.Dispatch<React.SetStateAction<string[]>>;
}

const FilterBox: React.FC<PropsType> = ({ criteria, onChange }) => {
  const [countries, setCountries] = useState<string[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    switch (criteria) {
      case "c":
        const newCountries = (
          localStorage.getItem("filterCountries") ||
          Object.keys(CONSTANTS.COUNTRIES).join(",")
        ).split(",");
        setCountries(newCountries);
        onChange(newCountries);
        break;
      case "l":
        const newLanguages = (
          localStorage.getItem("filterLanguages") ||
          Object.keys(CONSTANTS.LANGUAGES).join(",")
        ).split(",");
        setLanguages(newLanguages);
        onChange(newLanguages);
        break;
      default:
        break;
    }
  }, [criteria, onChange]);

  function handleCountriesChanged(e: React.SyntheticEvent<EventTarget>) {
    const target = e.target as HTMLFormElement;
    const newCountries = target.checked
      ? countries.concat([target.name])
      : countries.filter(item => item !== target.name);

    setCountries(newCountries);
    onChange(newCountries);
    localStorage.setItem("filterCountries", newCountries.join(","));
  }

  function handleLanguagesChanged(e: React.SyntheticEvent<EventTarget>) {
    const target = e.target as HTMLFormElement;
    const newLanguages = target.checked
      ? languages.concat([target.name])
      : languages.filter(item => item !== target.name);

    setLanguages(newLanguages);
    onChange(newLanguages);
    localStorage.setItem("filterLanguages", newLanguages.join(","));
  }

  return (
    <div className="block text-center text-sm mb-2">
      <div>
        <button
          className="bg-white text-gray-600 outline-none border rounded px-1 mb-2"
          onClick={() => setVisible(!visible)}
        >
          Options {visible ? "^" : "v"}
        </button>
      </div>
      <div
        className={
          "inline-block text-left border-b-2 mb-4" + (!visible ? " hidden" : "")
        }
      >
        {criteria.includes("c") && (
          <div className="px-2 pb-2">
            <div className="font-semibold text-lg">Countries</div>
            {Object.entries(CONSTANTS.COUNTRIES).map(([k, v]) => (
              <label className="mr-2" key={k}>
                <input
                  className="mr-1"
                  type="checkbox"
                  name={k}
                  checked={countries.includes(k)}
                  onChange={handleCountriesChanged}
                />
                <span>{v}</span>
              </label>
            ))}
          </div>
        )}
        {criteria.includes("l") && (
          <div className="px-2 pb-2">
            <div className="font-semibold text-lg">Languages</div>
            {Object.entries(CONSTANTS.LANGUAGES).map(([k, v]) => (
              <label className="mr-2" key={k}>
                <input
                  className="mr-1"
                  type="checkbox"
                  name={k}
                  checked={languages.includes(k)}
                  onChange={handleLanguagesChanged}
                />
                <span>{v}</span>
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBox;
