import React from "react";
import {
  RouteComponentProps,
  withRouter,
  Route,
  Switch
} from "react-router-dom";
import AuthService from "../../services/Auth";
import New from "../../pages/artists/New";
import Edit from "../../pages/artists/Edit";
import Show from "../../pages/artists/Show";
import Artists from "../../pages/artists/Artists";

const ArtistRouter: React.FC<RouteComponentProps> = props => {
  const { match } = props;

  const protectedRoutes = !!AuthService.getToken() && [
    <Route path={`${match.url}/new`} exact component={New} key="new" />,
    <Route path={`${match.url}/:slug/edit`} exact component={Edit} key="edit" />
  ];

  return (
    <Switch>
      {protectedRoutes}
      <Route path={`${match.url}/:slug`} exact component={Show} />
      <Route path={`${match.url}/`} exact component={Artists} />
    </Switch>
  );
};

export default withRouter(ArtistRouter);
