import React, { useState, useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import useAxios, { configure } from "axios-hooks";
import LRU from "lru-cache";
import CONSTANTS from "../../constants";
import { Song } from "../../types/songs/Song";
import Axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BaseHeader, BaseButton } from "../../components/Base";
import { Artist } from "../../types/artists/Artist";

interface SongMatchProps {
  slug: string;
}

const EditPage: React.FC<RouteComponentProps<SongMatchProps>> = (props) => {
  const { match } = props;

  const [artists, setArtists] = useState<Artist[]>();

  const [song, setSong] = useState<Song>({
    title: "",
    language: "de",
    lyrics: "",
    releasedOn: new Date().toISOString().slice(0, 10),
    slug: "",
  });

  const [{ data, loading, error }, executePatch] = useAxios(
    {
      url: `/contents/songs/${match.params.slug}`,
      method: "PATCH",
    },
    { manual: true }
  );

  useEffect(() => {
    async function fetchArtists() {
      try {
        const { data } = await Axios.get(
          `${CONSTANTS.API_ENDPOINT}creators/artists`
        );
        setArtists(data);
      } catch (error) {
        // setError
      }
    }
    fetchArtists();

    async function fetchSong() {
      try {
        const { data } = await Axios.get(
          `${CONSTANTS.API_ENDPOINT}contents/songs/${match.params.slug}`
        );
        data.artistId = data.artist.id;
        setSong(data);
      } catch (error) {
        // setError
      }
    }
    fetchSong();
  }, [match.params.slug]);

  const updateSong = () => {
    const cache = new LRU({ max: 20 });
    configure({ cache });

    executePatch({
      data: { song },
    });
  };

  if (data) return <Redirect to={`/songs/${data.slug}`} />;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>API Error</p>;

  const updateValue = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLFormElement;
    let newValue;

    switch (target.name) {
      case "youtubeId":
        const vIdIndex = target.value.indexOf("v=", 10) + 2;
        newValue =
          vIdIndex > 10 ? target.value.substr(vIdIndex, 11) : target.value;
        break;

      default:
        newValue = target.value;
        break;
    }

    setSong({ ...song, [target.name]: newValue });
  };

  return (
    <div>
      <BaseHeader title="Edit song" />
      <form className="flex flex-wrap">
        <div className="mb-4 w-full lg:w-3/4">
          <label className="block text-sm font-bold mb-2">Title</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="title"
            value={song.title}
            onChange={updateValue}
          />
        </div>
        <div className="mb-4 w-full lg:w-1/4">
          <label className="block text-sm font-bold mb-2">Youtube Id</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            type="text"
            name="youtubeId"
            value={song.youtubeId}
            onChange={updateValue}
          />
        </div>
        <div className="mb-4 w-full lg:w-1/3">
          <label className="block text-sm font-bold mb-2">Artist</label>
          <select
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="artistId"
            value={song.artistId}
            onChange={updateValue}
          >
            {!!artists &&
              artists.map((artist) => (
                <option value={artist.id} key={artist.id}>
                  {artist.slug}
                </option>
              ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/3">
          <label className="block text-sm font-bold mb-2">Language</label>
          <select
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="language"
            value={song.language}
            onChange={updateValue}
          >
            {Object.entries(CONSTANTS.LANGUAGES).map(([k, v]) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 w-full lg:w-1/3">
          <label className="block text-sm font-bold mb-2">Released on *</label>
          <DatePicker
            selected={new Date(song.releasedOn)}
            onChange={(date: Date) =>
              setSong({
                ...song,
                releasedOn: date.toISOString().slice(0, 10) || "",
              })
            }
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            wrapperClassName="w-full"
            dateFormat="yyyy/MM/dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Now"
          />
        </div>
        <div className="mb-4 w-full">
          <label className="block text-sm font-bold mb-2">Lyrics</label>
          <textarea
            className="shadow appearance-none border rounded w-full p-2 focus:outline-none focus:shadow-outline"
            name="lyrics"
            rows={14}
            value={song.lyrics}
            onChange={updateValue}
          />
        </div>
        <div className="flex w-full justify-end">
          <BaseButton title="Submit" onClick={updateSong} />
        </div>
      </form>
    </div>
  );
};

export default EditPage;
