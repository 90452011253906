import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className="w-full bg-indigo-800 text-gray-200 mt-2">
      <div className="container mx-auto flex flex-wrap text-center text-white py-2">
        <div className="flex flex-col w-1/2 py-1 leading-relaxed">
          <div className="my-3 text-lg font-semibold">ABOUT US</div>
          <Link to="/about" className="hover:text-gray-500">
            About
          </Link>
          <Link to="/how-it-works" className="hover:text-gray-400">
            How It Works
          </Link>
        </div>
        <div className="flex flex-col w-1/2 py-1 leading-relaxed">
          <div className="my-3 text-lg font-semibold">SERVICES</div>
          <Link to="/artists" className="hover:text-gray-400">
            Artists
          </Link>
          <Link to="/songs" className="hover:text-gray-500">
            Songs
          </Link>
        </div>
        <div className="w-full py-3 text-gray-300 text-xs">
          <a
            href="//www.buenaworks.com"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold hover:text-gray-400"
          >
            Buenaworks
          </a>
          , 2019. All rights reserved. Lyrics are reserved for their owners.
          <p>Made from Seoul with Coffee.</p>
        </div>
        <div className="flex justify-around w-full py-1 text-gray-300 text-xs"></div>
      </div>
    </div>
  );
};
export default Footer;
